import React, { useState } from 'react'
import useWindowSize from '../CommonFuction/useWindowSize';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ContactUsForm from './ContactUsForm';

export default function ContactUsDailog() {
    const [visible, setVisible] = useState(false);
    const { windowWidth } = useWindowSize();
    return (
        <div className="right-btn-title">
            {windowWidth > 499 ? <Button label="Get in touch" onClick={() => setVisible(true)} className='btn-get-in-touch' /> :
                <i class="fa-solid fa-address-book" onClick={() => setVisible(true)}></i>}
            <Dialog
                visible={visible}
                header='Contact Us'
                modal
                className='contactusdailog'
                dismissableMask={() => setVisible(true)}
                onHide={() => { if (!visible) return; setVisible(false); }}
                blockScroll={true}
            >
                <GoogleReCaptchaProvider reCaptchaKey='6LcOoE8qAAAAABCld3Yau2Gbv-k6hdKGyftJ67N9'><ContactUsForm isQuotation={true}/></GoogleReCaptchaProvider>
            </Dialog>
        </div>
    )
}
