
import React from 'react'

const ActivateLicense = ({ color }) => {
    return (
        <>
           
                <svg width="24" height="24" viewBox="0 0 50 50" fill="gray" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"stroke="gray" clip-rule="evenodd" d="M45.8333 13.9998C45.8333 19.4311 41.4124 23.8332 35.9583 23.8332C34.9645 23.8332 32.6978 23.604 31.5958 22.6894L30.2187 24.0623C29.4083 24.8686 29.627 25.1061 29.9895 25.4978C30.1374 25.6603 30.3124 25.8519 30.4478 26.1207C30.4478 26.1207 31.5958 27.7207 30.4478 29.3228C29.7603 30.2373 27.8312 31.5165 25.627 29.3228L25.1687 29.7811C25.1687 29.7811 26.5437 31.3811 25.3978 32.9811C24.7083 33.8957 22.8708 34.8103 21.2624 33.2103L19.6562 34.8103C18.552 35.9082 17.2062 35.2686 16.6708 34.8103L15.2937 33.4373C14.0083 32.1582 14.7583 30.7707 15.2937 30.2373L27.2333 18.3457C27.2333 18.3457 26.0874 16.5165 26.0874 13.9998C26.0874 8.56859 30.5062 4.1665 35.9603 4.1665C41.4145 4.1665 45.8333 8.56859 45.8333 13.9998ZM39.4041 13.9998C39.4041 15.8957 37.8624 17.4311 35.9603 17.4311C35.5087 17.4322 35.0614 17.3443 34.6438 17.1724C34.2261 17.0005 33.8465 16.7481 33.5265 16.4294C33.2065 16.1108 32.9524 15.7322 32.7788 15.3153C32.6051 14.8984 32.5153 14.4514 32.5145 13.9998C32.5145 12.104 34.0562 10.5686 35.9603 10.5686C36.4118 10.5678 36.8589 10.6559 37.2763 10.8279C37.6937 10.9999 38.0731 11.2524 38.3929 11.571C38.7126 11.8896 38.9665 12.2681 39.14 12.6848C39.3135 13.1016 39.4033 13.5484 39.4041 13.9998Z" />
                    <path stroke="gray"  d="M27.4915 4.16859C26.0697 5.38213 24.9282 6.88983 24.1458 8.58757C23.3635 10.2853 22.959 12.1326 22.9603 14.0019C22.9603 15.4186 23.2248 16.6603 23.529 17.6228L13.0873 28.0228C12.2654 28.8471 11.7195 29.9062 11.5248 31.054C11.2707 32.6103 11.7228 34.2936 13.0873 35.6519L14.4665 37.0248C14.5221 37.0804 14.5804 37.1346 14.6415 37.1873C15.4566 37.8723 16.4437 38.3212 17.4957 38.4853C18.2832 38.6054 19.0881 38.5372 19.8442 38.2863C20.6003 38.0353 21.2862 37.6087 21.8457 37.0415C22.6478 37.2436 23.4707 37.2603 24.2623 37.1082C25.7299 36.8205 27.0386 35.9987 27.9353 34.8019C28.2116 34.4158 28.4269 34.029 28.5811 33.6415C28.7844 33.6236 28.9867 33.5958 29.1873 33.5582C30.7103 33.2528 32.065 32.3913 32.9873 31.1415C33.438 30.524 33.76 29.8222 33.9343 29.0778C34.1086 28.3334 34.1316 27.5616 34.0019 26.8082C36.1829 27.1427 38.4131 26.9167 40.4826 26.1513C42.5521 25.3859 44.3927 24.1064 45.8311 22.4332L45.8332 24.9998C45.8332 34.8207 45.8332 39.7311 42.7811 42.7811C39.7332 45.8332 34.8207 45.8332 24.9998 45.8332C15.179 45.8332 10.2686 45.8332 7.2165 42.7811C4.1665 39.7332 4.1665 34.8207 4.1665 24.9998C4.1665 15.179 4.1665 10.2686 7.2165 7.2165C10.2707 4.1665 15.179 4.1665 24.9998 4.1665L27.4915 4.16859Z"  />
                </svg>
            
        </>
    );
};
export default ActivateLicense;