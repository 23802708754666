import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        // Redirect to the home page after 2 seconds
        const timer = setTimeout(() => {
            navigate('/');
        }, 100);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <div>
        </div>
    );
};

export default NotFound;
