import React, { useEffect, useState } from 'react'
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag'
import ProductPage from './ProductPage/ProductPage'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Magamenu from '../Navbar/Megamenu';

const Helpdesk = () => {

    useEffect(() => {
        document.title = "Helpdesk";
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <ReactHelmetTag
                link="https://www.sharepointempower.com//helpdesk-system"
                metaTitle="Sharepoint HelpDesk System"
                metaDescription="SharepointEmpower Pvt. Ltd.'s Helpdesk System is a meticulously designed software solution for streamlined support interactions. It centralizes inquiries, requests, and technical issues, enhancing operational efficiency and customer satisfaction. The system ensures precise tracking of support activities, streamlined communication, and data-driven insights for informed decision-making."
                metaKeywords="HelpDesk System, IT service desk app, sharepoint online helpdesk ticket system, microsoft service desk tool, office 365 help desk app, office 365 help desk software, microsoft teams help desk ticketing system, microsoft 365 help desk ticketing system, sharepoint ticketing system app, office 365 support ticket system, microsoft help desk ticketing system, microsoft teams helpdesk app, sharepoint help desk software, sharepoint ticketing system, sharepoint helpdesk system, help desk software for business, top IT helpdesk software, hr service desk software, top helpdesk ticketing systems, top help desk ticketing systems, helpdesk and ticketing system, open source ticketing tools for windows, IT support ticketing system open source, help desk issue tracking software, best helpdesk solutions, top 10 help desk software, top 10 service desk software, top 10 IT help desk software, help desk and ticketing, helpdesk ticket, help desk microsoft office 365, maintenance help desk software, helpdesk and asset management software, help desk products, help desk app, best open source help desk software, open source ticket management software, open source ticket management, IT help desk and asset management software, helpdesk system open source, helpdesk software provided by deskpro, IT service ticket system, help desk case management software, help desk ticketing system for small business, helpdesk ticketing system software, helpdesk ticketing software, enterprise help desk solution, helpdesk ticketing service, cheapest help desk software, help desk ticket management system, help desk software system."
            />
            <Magamenu />
            <LazyLoadComponent>
                <ProductPage project="Helpdesk System" />
            </LazyLoadComponent>
        </div>
    )
}

export default Helpdesk