
import React from 'react'

export default function InstallationGuide({ color }) {
    return (
        <svg width="24" height="24" viewBox="0 0 50 50" fill="gray" xmlns="http://www.w3.org/2000/svg">
            <path d="M40.625 40.625H43.75V50H3.125V40.625H6.25V46.875H40.625V40.625ZM37.0361 27.6611L23.4375 41.2598L9.83887 27.6611L12.0361 25.4639L21.875 35.2783V3.125H25V35.2783L34.8389 25.4639L37.0361 27.6611Z" fill="gray" />
        </svg>
    )
}
