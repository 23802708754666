import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const ApiDataContext = createContext();

const globalConfig = {
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
};

const fetchData = async (url, setData) => {
  try {
    const response = await axios.get(url, globalConfig);
    setData(response.data);
  } catch (error) {
    console.error(error);
  }
};

const ApiDataProvider = ({ children }) => {
  const [apidata, setApiData] = useState([]);
  const [apiProductPagedata, setProductPageData] = useState([]);
  const [apiFAQdata, setFAQData] = useState([]);
  const [apiJobOpeningdata, setJobOpeningdata] = useState([]);

  useEffect(() => {
    fetchData("https://api.sharepointempower.com/api/Sharepoint", setApiData);
    fetchData("https://api.sharepointempower.com/api/sharepoint/getproductpage", setProductPageData);
    fetchData("https://api.sharepointempower.com/api/sharepoint/getFAQpage", setFAQData);
    fetchData("https://api.sharepointempower.com/api/sharepoint/getJobOpeningPage", setJobOpeningdata);
  }, []);

  return (
    <ApiDataContext.Provider value={{ apidata, apiProductPagedata, apiFAQdata, apiJobOpeningdata }}>
      {children}
    </ApiDataContext.Provider>
  );
};

export default ApiDataProvider;
