import React, { useEffect, useState, useContext } from 'react';
import { MegaMenu } from 'primereact/megamenu';
import { Ripple } from 'primereact/ripple';
import { Link } from 'react-router-dom';
import './Magamenu.css';
import useLocalStorage from 'use-local-storage';
import { ApiDataContext } from '../CommonComponents/Userguide/ApiDataContext';
import TranslateWidget from '../CommonComponents/Translate/Translate';
import useWindowSize from '../CommonComponents/CommonFuction/useWindowSize';

export default function Magamenu() {
    const { apiProductPagedata } = useContext(ApiDataContext);
    const [items, setItems] = useState([]);
    const { windowWidth } = useWindowSize();

    const toggleTheme = () => {
        var element = document.body;
        element.classList.toggle("dark-mode-body");
    }
    const [theme, setTheme] = useLocalStorage('theme', toggleTheme ? 'dark-mode-body' : 'light');

    const applyTheme = (selectedTheme) => {
        const element = document.body;
        element.classList.toggle("dark-mode-body", selectedTheme === 'dark-mode-body');
    }

    const toggleBtn = () => {
        if (theme === 'light') {
            setTheme('dark-mode-body')
        }
        else {
            setTheme('light')
        }
    }

    useEffect(() => {
        headermanu()
        applyTheme(theme)
    }, [apiProductPagedata, theme]);

    const headermanu = () => {
        const isBigScreen = windowWidth >= 768;
        if (apiProductPagedata && apiProductPagedata.length > 0) {

            const dynamicItemsLeft = apiProductPagedata.slice(0, 3)?.map(product => ({
                label: product.projectTitle,
                image: getLogoForProduct(product.projectTitle),
                subtext: isBigScreen ? product.menuProductDescription : '',
                path: product.routPath,
                template: itemRenderer
            }));
            const dynamicItemsCenter = apiProductPagedata.slice(3, 6)?.map(product => ({
                label: product.projectTitle,
                image: getLogoForProduct(product.projectTitle),
                subtext: isBigScreen ? product.menuProductDescription : '',
                path: product.routPath,
                template: itemRenderer
            }));
            const dynamicItemsRight = apiProductPagedata.slice(6, 9)?.map(product => ({
                label: product.projectTitle,
                image: getLogoForProduct(product.projectTitle),
                subtext: isBigScreen ? product.menuProductDescription : '',
                path: product.routPath,
                template: itemRenderer
            }));

            const updatedItems = [
                {
                    label: 'Products',
                    root: true,
                    template: itemRenderer,
                    path: '/all-products',
                    className: 'product-dropdown',
                    items: [
                        [
                            {
                                items: dynamicItemsLeft,

                            }
                        ],
                        [
                            {
                                items: dynamicItemsCenter,

                            }
                        ],
                        [
                            {
                                items: dynamicItemsRight,

                            }
                        ]
                    ]

                },
                {
                    label: 'Pricing',
                    root: true,
                    template: itemRenderer,
                    path: '/product-pricing-list',
                },
                {
                    label: 'Company',
                    className: 'company',
                    root: true,
                    template: itemRenderer,
                    items: [
                        [
                            {
                                items: [
                                    {
                                        label: 'About Us',
                                        icon: 'fa-solid fa-circle-info icon-size',
                                        path: '/about-us',
                                        template: itemRenderer
                                    },
                                    {
                                        label: 'Find Us',
                                        icon: 'fa-sharp fa-solid fa-map-location-dot icon-size',
                                        path: '/find-us',
                                        template: itemRenderer
                                    }
                                ]
                            }
                        ],
                        [
                            {
                                items: [
                                    {
                                        label: 'Career at SharepointEmpower',
                                        icon: 'fa-solid fa-users-line icon-size',
                                        path: '/career-at-sharepointempower',
                                        template: itemRenderer
                                    },
                                    {
                                        label: 'Contact Us',
                                        icon: 'fa-sharp fa-solid fa-address-card icon-size',
                                        path: '/contact-us',
                                        template: itemRenderer
                                    }
                                ]
                            }
                        ]
                    ]
                },
                {
                    label: 'FAQs',
                    root: true,
                    template: itemRenderer,
                    path: '/faqs',
                },
                {
                    label: 'Language',
                    root: true,
                    template: () => {
                        return (<div className='translat-drop w-4rem justify-content-end' style={{ position: 'relative' }}>
                            <TranslateWidget />
                        </div>)
                    }
                },
                {
                    template: () => {
                        return (<li className="nav-item">
                            {windowWidth <= 991 ?
                                <div className="nav-link nav-link-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarNav"
                                    aria-controls="navbarNav"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                    id='toggleTheme' onClick={() => {
                                        toggleTheme()
                                        toggleBtn()
                                    }}>
                                    {theme == "dark-mode-body" ?
                                        <i className="fa-solid fa-moon toggle-image" aria-hidden="true"></i>
                                        : <i className="fa-solid fa-sun toggle-image" aria-hidden="true"></i>}
                                </div>
                                :
                                <div className="nav-link nav-link-toggler"
                                    id='toggleTheme' onClick={() => {
                                        toggleTheme()
                                        toggleBtn()
                                    }}>
                                    {theme == "dark-mode-body" ?
                                        <i className="fa-solid fa-moon toggle-image" aria-hidden="true"></i>
                                        : <i className="fa-solid fa-sun toggle-image" aria-hidden="true"></i>}
                                </div>
                            }
                        </li>);
                    }
                }

            ];

            setItems(updatedItems);
            // setLoading(false);
        }
    }

    function getLogoForProduct(productName) {
        switch (productName) {
            case 'Asset Management System':
                return theme === 'light' ? require('../../assets/images/icons/AMS-w.png') : require('../../assets/images/icons/AMS-b.png');
            case 'Learning Management Portal':
                return theme === 'light' ? require('../../assets/images/icons/LMP-w.png') : require('../../assets/images/icons/LMP-b.png');
            case 'Audit Inspection':
                return theme === 'light' ? require('../../assets/images/icons/audit-w.png') : require('../../assets/images/icons/audit-b.png');
            case 'Construction Management System':
                return theme === 'light' ? require('../../assets/images/icons/CMS-w.png') : require('../../assets/images/icons/CMS-b.png');
            case 'SPE Intranet':
                return theme === 'light' ? require('../../assets/images/icons/spe-Intranet-w.png') : require('../../assets/images/icons/spe-Intranet-b.png');
            case 'Helpdesk System':
                return theme === 'light' ? require('../../assets/images/icons/HD-w.png') : require('../../assets/images/icons/HD-b.png');
            case 'Leave Management System':
                return theme === 'light' ? require('../../assets/images/icons/leave-ms-w.png') : require('../../assets/images/icons/leave-ms-b.png');
            case 'Inventory Management System':
                return theme === 'light' ? require('../../assets/images/icons/IMS-w.png') : require('../../assets/images/icons/IMS-b.png');
            case 'Approval System':
                return theme === 'light' ? require('../../assets/images/icons/AS-w.png') : require('../../assets/images/icons/AS-b.png');
            default:
                return null;
        }
    }


    const itemRenderer = (item) => {
        let updatedTitle = item.title;
        if (item.root) {
            return (
                <Link to={item.path} className="flex align-items-center cursor-pointer overflow-hidden relative p-ripple">
                    <span className={item.icon} />
                    <span className="header-font-bold get-in-touch-broder">{item.label}</span>
                    <Ripple />
                </Link>
            );
        } else if (!item.image) {
            return (
                <Link to={item.path} className="flex align-items-center cursor-pointer gap-2">
                    {item.icon && (
                        <span className="inline-flex align-items-center justify-content-center border-circle h-2rem">
                            <i className={item.icon}></i>
                        </span>
                    )}
                    <span className="inline-flex flex-column company-menu-underline">
                        <span>{item.label}</span>
                        <span>{item.subtext}</span>
                    </span>
                </Link>
            );
        }
        {
            return (
                <Link to={item.path} className="flex flex-column gap-2">
                    <div className="flex gap-1 flex-item-img subtext">
                        <img alt="megamenu-demo" className='w-2rem productimage' src={item.image} />
                        <div className='mobile-text'>
                            <span className='productfont'>{item.label}</span>
                            {theme == "dark-mode-body" ?
                                <img className="microsoftbadge ml-1" src={require('../../../src/assets/images/Microsoft_Badge_black.png')} alt={updatedTitle} /> :
                                <img className="microsoftbadge ml-1" src={require('../../../src/assets/images/Microsoft_Badge_white.png')} alt={updatedTitle} />}
                            {item.subtext && <p className='text-sm font-normal line-height-3 subtext'>{item.subtext}</p>}
                        </div>
                    </div>
                </Link>
            );
        }
    };

    const start = (
        <Link to="/">
            {theme == "dark-mode-body" ?
                <img
                    src={require("../../assets/images/SPE_logo_black_navbar.png")}
                    className="logo d-inline-block align-text-top" /> :
                <img
                    src={require("../../assets/images/SPE_white_logo_HD.png")}
                    className="logo d-inline-block align-text-top" />
            }
            {/* <img
                src={require("../../assets/images/SPE_white_logo_HD.png")}
                className="logo d-inline-block" /> */}
        </Link>
    );
    return (
        <div className="megamenu-container scrolled">
            {/* <div style={{ position: "none" }} className="logo">{start}</div> */}
            <MegaMenu start={start} model={items} orientation="horizontal" breakpoint="960px">
            </MegaMenu>
        </div>
    );
}
