import React, { useEffect, useState } from 'react'
import PageHeadingContent from '../../CommonComponents/PageHeadingContent/PageHeadingContent'
import { CardData } from './ServiceData'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const CaseStudies = () => {

    const [serviceimgcarddata, setServiceImgCardData] = useState([])

    useEffect(() => {
        setServiceImgCardData(CardData)
    }, [])


    return (
        <div>

            <PageHeadingContent heading="Custom Software Development" subtitle="Transform your ideas into reality with our custom software development service" content="Our custom software development process is designed to be transparent, collaborative, and flexible. We work closely with our clients to ensure that we understand their needs and goals, and we provide regular updates throughout the development process to ensure that they are satisfied with the progress." />

            <div classNameName='container-fluid'>
                <div classNameName='container'>
                    <div className="row">
                        <div className="col col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                            <div className="light-mode-content">
                                <p className="h5-title-lm text-align-center second-p-anim">
                                    Industries We Serve
                                </p>
                            </div>
                            <div className="container light-mode-content">
                                <p className="p-center-content-lm first-p-anim">
                                    Our company is dedicated to providing advanced solutions and knowledge processing technology solutions
                                    for businesses in various industries. We specialize in the following areas:
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div classNameName='container-fluid'>
                <div classNameName='container'>

                    {/* <!-- Row 1 starts --> */}
                        <div className="row si-img-row-1-anim service-inner-img-card-container d-flex-center-custom">
                            {
                                serviceimgcarddata.map(ele => {
                                    return (
                                        <div className="col col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                            {/* <a href="#"> */}
                                            <div className="service-inner-img-card-body">
                                                <LazyLoadImage className="si2-img-card"
                                                    src={require(`../../../assets/images/service_inner_page_2/${ele.cardImg}.png`)}
                                                    alt={ele.cardImg}
                                                    effect="blur"
                                                    visibleByDefault={ele.cardImg}
                                                />
                                                <div className="d-flex-center-custom">
                                                    <div className="si2-img-overlay"></div>
                                                    <span className="si2-img-overlay-span">{ele.cardImgTitle}</span>
                                                </div>
                                            </div>
                                            {/* </a> */}
                                        </div>
                                    )
                                })
                            }
                            <div className="col col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                <div className="service-inner-img-card-body">

                                </div>
                            </div>
                        </div>
                    {/* <!-- Row 1 ends --> */}
                </div>
            </div>
        </div>
    )
}

export default CaseStudies