import React, { useEffect, useState } from 'react'
import 'react-lazy-load-image-component/src/effects/blur.css';
import ReactHelmetTag from '../CommonComponents/ReactHelmet/ReactHelmetTag'
import "primereact/resources/themes/lara-light-indigo/theme.css";
import ProductPage from './ProductPage/ProductPage'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Magamenu from '../Navbar/Megamenu';

const LearningManagementPortal = () => {

  useEffect(() => {
    document.title = "Learning Management Portal";
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>

      <ReactHelmetTag
        link="https://www.sharepointempower.com/learning-management-system"
        metaTitle="Microsoft Sharepoint Learning Management System"
        metaDescription="Unlock the full potential of Microsoft SharePoint with our best learning management system (LMS). Streamline training and educational content delivery, track learner progress, and foster collaboration. Discover how our SharePoint LMS can revolutionize your learning initiatives today."
        metaKeywords="learning management system, Sharepoint learning management system, learner management system, best learning management system, learning management system software, online learning management system, microsoft learning management system, sharepoint lms"
      />

      <Magamenu />
      <LazyLoadComponent>
        <ProductPage project="Learning Management Portal" />
      </LazyLoadComponent>
    </div>
  )
}

export default LearningManagementPortal