
export const CardData = [
    {
        cardImg: "card1",
        cardImgTitle: "Automobile"
    },
    {
        cardImg: "card2",
        cardImgTitle: "Aviation"
    },
    {
        cardImg: "card3",
        cardImgTitle: "Banking & Finance"
    },
    {
        cardImg: "card4",
        cardImgTitle: "Construction & Mining"
    },
    {
        cardImg: "card5",
        cardImgTitle: "Consultancy & Services"
    },
    {
        cardImg: "card6",
        cardImgTitle: "Laboratory"
    },
    {
        cardImg: "card7",
        cardImgTitle: "Recruitment"
    },
    {
        cardImg: "card8",
        cardImgTitle: "Legal"
    },
    {
        cardImg: "card9",
        cardImgTitle: "Pharmacy & Healthcare"
    },
    {
        cardImg: "card9",
        cardImgTitle: "Security Solutions"
    },
    {
        cardImg: "card10",
        cardImgTitle: "Real Estate"
    }
]

export const ServiceInner2SectionContent = [
    {
        heading: "Technology",
        subtitle: "Revolutionizing your business with innovative technologies",
        content: "Designing and developing innovative and professional software solutions for hundreds of companies across global tech marketers for almost a decade now. "
    }
]