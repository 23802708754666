
import React from 'react'

export default function UserGuide({ color }) {
    return (
        <svg width="24" height="24" viewBox="0 0 50 50" fill="gray" xmlns="http://www.w3.org/2000/svg">
            <path stroke="gray" d="M25.0002 8.3335C27.2103 8.3335 29.3299 9.21147 30.8927 10.7743C32.4555 12.3371 33.3335 14.4567 33.3335 16.6668C33.3335 18.877 32.4555 20.9966 30.8927 22.5594C29.3299 24.1222 27.2103 25.0002 25.0002 25.0002C22.79 25.0002 20.6704 24.1222 19.1076 22.5594C17.5448 20.9966 16.6668 18.877 16.6668 16.6668C16.6668 14.4567 17.5448 12.3371 19.1076 10.7743C20.6704 9.21147 22.79 8.3335 25.0002 8.3335ZM25.0002 29.1668C34.2085 29.1668 41.6668 32.896 41.6668 37.5002V41.6668H8.3335V37.5002C8.3335 32.896 15.7918 29.1668 25.0002 29.1668Z" fill="gray" />
        </svg>
    )
}

