import React, { useState, useEffect } from 'react';
import './Banner.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import useWindowSize from '../CommonComponents/CommonFuction/useWindowSize';

const Banner = () => {

    const [isScrolled, setIsScrolled] = useState(false);
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const { windowWidth } = useWindowSize();

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            setIsScrolled(scrollTop > 200);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div>
                <div className="container-fluid">
                    <div className="banner-container">
                        <div style={{ position: 'fixed', width: '100%', height: '100%' }}>
                            <div className='loadimg'>
                                <>
                                    {!isVideoLoaded && (
                                        <img
                                            src={require("../../../src/assets/images/bannervideoload.jpg")}
                                            alt="Placeholder"
                                            className="video-placeholder"
                                            loading='lazy'
                                        />
                                    )}
                                    {windowWidth > 770 ?
                                        <video id='myVideo' height="100%" width="100%" autoPlay loop muted playsInline className={`bannervideo ${isScrolled ? 'scrolledbanner' : ''} pointer-events-none`} onCanPlay={() => setIsVideoLoaded(true)}>
                                            <source src="https://www.sharepointempower.com/assets/videos/LandingPageVideo/SharepointEmpower.mp4" type='video/mp4' />
                                        </video>
                                        :
                                        <video id='myVideo' height="100%" width="100%" autoPlay loop muted playsInline className={`bannervideo ${isScrolled ? 'scrolledbanner' : ''} pointer-events-none`} onCanPlay={() => setIsVideoLoaded(true)}>
                                            <source src="https://www.sharepointempower.com/assets/videos/LandingPageVideo/SharepointEmpower-2.mp4" type='video/mp4' />
                                        </video>
                                    }
                                </>

                            </div>
                            <div style={windowWidth > 450 ? { position: 'absolute', bottom: 30, right: 0, width: '20%' } : { position: 'absolute', bottom: 30, right: 0, width: '25%' }} className={`microsoft-icon-bannervideo ${isScrolled ? 'scrolledbanner' : ''}`}>
                                <LazyLoadImage
                                    className="microsoft-icon w-9"
                                    loading='lazy'
                                    src={require("../../assets/images/service_inner_page/Microsoft-solution-partner-white.png")}
                                    alt="Microsoft-solution-partner"
                                    visibleByDefault={require("../../assets/images/service_inner_page/Microsoft-solution-partner-white.png")}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Banner;
